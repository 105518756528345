const invitingRouter = {

        route: null,
        name: null,
        title: '邀请涵管理',
        type: 'folder', // 类型: folder, tab, view
        icon: 'iconfont icon-tushuguanli',
        filePath: 'view/inviting/', // 文件路径
        order: null,
        inNav: true,
        children: [
          {
            title: '邀请涵列表',
            type: 'view',
            name: 'invitingCreate',
            route: '/inviting/list',
            filePath: 'view/inviting/inviting-list.vue',
            inNav: true,
            icon: 'iconfont icon-tushuguanli',
          }
        ],
    }
    
    export default invitingRouter