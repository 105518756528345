const categoryRouter = {
    route: null,
    name: null,
    title: '分类管理',
    type: 'folder', // 类型: folder, tab, view
    icon: 'iconfont icon-tushuguanli',
    filePath: 'view/category/', // 文件路径
    order: null,
    inNav: true,
    children: [
      {
        title: '分类列表',
        type: 'view',
        name: 'categoryCreate',
        route: '/category/list',
        filePath: 'view/category/category-list.vue',
        inNav: true,
        icon: 'iconfont icon-tushuguanli',
      },
      {
        title: '子分类列表',
        type: 'tab',
        name: 'subCategoryList',
        route: '/sub-category/:id/list',
        filePath: 'view/category/sub-category-list.vue',
        inNav: false,
        icon: '',
      },
    ],
  }
  
  export default categoryRouter
  