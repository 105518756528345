const clientRouter = {
        route: null,
        name: null,
        title: 'C端数据',
        type: 'folder', // 类型: folder, tab, view
        icon: 'iconfont icon-tushuguanli',
        filePath: 'view/client/', // 文件路径
        order: null,
        inNav: true,
        children: [
          {
            title: 'C端用户列表',
            type: 'view',
            name: 'ClientUserList',
            route: '/client/user/list',
            filePath: 'view/client/user-list.vue',
            inNav: true,
            icon: '',
          },
        ],
      }
      
      export default clientRouter
      