const substanceRouter = {
    route: null,
    name: null,
    title: '服务管理',
    type: 'folder', // 类型: folder, tab, view
    icon: 'iconfont icon-tushuguanli',
    filePath: 'view/substance/', // 文件路径
    order: null,
    inNav: true,
    children: [
      {
        title: '服务列表',
        type: 'view',
        name: 'substanceCreate',
        route: '/substance/list',
        filePath: 'view/substance/substance-list.vue',
        inNav: true,
        icon: 'iconfont icon-tushuguanli',
      }
    ],
  }
  
  export default substanceRouter
  