const noticeRouter = {

    route: null,
    name: null,
    title: '通知管理',
    type: 'folder', // 类型: folder, tab, view
    icon: 'iconfont icon-tushuguanli',
    filePath: 'view/notice/', // 文件路径
    order: null,
    inNav: true,
    children: [
      {
        title: '通知列表',
        type: 'view',
        name: 'noticeCreate',
        route: '/notice/list',
        filePath: 'view/notice/notice-list.vue',
        inNav: true,
        icon: 'iconfont icon-tushuguanli',
      }
    ],
}

export default noticeRouter